import { getApplicationInsights } from '@dvag/dfs-application-insights-react';
import { getApiUrls } from '@dvag/dfs-constant-config';
import { getEnvironmentConfig } from '@dvag/dfs-constant-config/app-urls';

const getAppInsights = () => {
  const connectionString = getApiUrls(getEnvironmentConfig().env).appInsights;
  const { appInsights, reactPlugin } = getApplicationInsights(connectionString, 'login');
  return { appInsights, reactPlugin };
}

export default getAppInsights;
