import styled, { css } from 'styled-components';
import { DxText } from '@dvag/design-system-react';

export const CookieConsentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const LinkText = styled(DxText)`
  ${({ theme: { spaces } }) => css`
    margin-left: ${spaces.x05};
  `};
`;
