import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  defineCustomElements as designSystemDefineElements,
} from '@dvag/design-system/loader';
import {
  defineCustomElements as defineDesignSystemIcons,
} from '@dvag/design-system-icons/loader';
import { addInitListener } from '@dvag/dfs-orchestrator-client';
import App from './App';
import { getLocale } from './locales';

const htmlEl =
  document.documentElement || document.getElementsByTagName('html')[0];
htmlEl.lang = getLocale();

const initializeApp = () => {
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );

  designSystemDefineElements();

  defineDesignSystemIcons();
};

addInitListener(initializeApp);

export default { initializeApp };
