import moment from 'moment';

export const deleteCookie = (name: string): void => {
  const hostName =
    window.location.hostname === 'localhost'
      ? window.location.hostname
      : `.${window.location.hostname}`;

  document.cookie = `${name}=; Max-Age=-1; path=/;domain=${hostName};`;
};

export interface LifeSpanType {
  period: moment.DurationInputArg1;
  type: moment.unitOfTime.DurationConstructor;
}

export const setCookie = (
  name: string,
  value: string,
  lifeSpan?: LifeSpanType,
): void => {
  const data: LifeSpanType = lifeSpan || { period: 1, type: 'day' };

  deleteCookie(name);
  const hostName =
    window.location.hostname === 'localhost'
      ? window.location.hostname
      : `.${window.location.hostname}`;

  const date = moment().add(data.period, data.type).toDate();
  const cookie = `${name}=${value};path=/;expires=${date};domain=${hostName};`;
  document.cookie = cookie;
};

export const setSessionCookie = (name: string, value: string): void => {
  deleteCookie(name);
  const hostName =
    window.location.hostname === 'localhost'
      ? window.location.hostname
      : `.${window.location.hostname}`;

  const cookie = `${name}=${value};path=/;domain=${hostName};`;
  document.cookie = cookie;
};

export const getCookieValue = (name: string): string => {
  const match = new RegExp(`(^| )${name}=([^;]+)`).exec(document.cookie);
  const index = 2

  return match ? match[index] : '';
};
