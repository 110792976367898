import { PADDING, FONTSIZE, SPACES, LAYOUT, BREAKPOINTS } from './core';

const theme = {
  padding: PADDING,
  fontSize: FONTSIZE,
  spaces: SPACES,
  layout: LAYOUT,
  breakpoints: BREAKPOINTS,
};

export default theme;
