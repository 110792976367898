const PADDING = {
  x0: '0',
  x1: '5px',
  x2: '10px',
  x3: '15px',
  x4: '20px',
  x5: '25px',
  x6: '30px',
  x7: '35px',
  x8: '40px',
  x9: '45px',
  x10: '50px',
  x11: '90px',
  x12: '250px',
};

const FONTSIZE = {
  x0: '0',
  x1: '8px',
  x2: '10px',
  x3: '12px',
  x4: '14px',
  x5: '16px',
  x6: '18px',
  x7: '20px',
  x8: '22px',
  x9: '24px',
  x10: '26px',
  x11: '28px',
  x12: '30px',
  x13: '32px',
  x14: '34px',
  x15: '36px',
  x16: '38px',
  x17: '40px',
  x18: '42px',
  x19: '44px',
};

const SPACES = {
  x0: '0',
  x025: '2px',
  x05: '4px',
  x075: '6px',
  x1: '8px',
  x105: '10px',
  x175: '12px',
  x2: '16px',
  x250: '20px',
  x3: '24px',
  x4: '32px',
  x5: '40px',
};

const LAYOUT = {
  x1: '538px',
  x2: '416px',
  x3: '286px',
  x4: '378px',
  x5: '258px',
};

const BREAKPOINTS = {
  xxsm: '340px',
  xsm2: '476px',
  xsm: '576px',
  sm: '768px',
  md: '992px',
  lg: '1024px',
  xlg: '1200px',
  intXXsm: 340,
  intXsm2: 476,
  intXsm: 576,
  intSm: 768,
  intMd: 992,
  intLg: 1024,
  intXlg: 1200,
};
export { PADDING, SPACES, FONTSIZE, LAYOUT, BREAKPOINTS };
