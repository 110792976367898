import React from 'react';
import i18next from 'i18next';
import {
  DxFdSeparator,
  DxGrid,
  DxSpacer,
  DxText,
  DxTextList,
} from '@dvag/design-system-react';

import Layout from '../../components/Layout';

import style from './Error403.module.css';

const Error403 = () => {
  const labels = {
    status: i18next.t('errors.error403.status'),
    accessDenied: i18next.t('errors.error403.accessDenied'),
    insufficientPermission: i18next.t('errors.error403.insufficientPermission'),
    grantPermission: i18next.t('errors.error403.grantPermission'),
    access: i18next.t('errors.error403.access'),
    search: i18next.t('errors.error403.search'),
    creation: i18next.t('errors.error403.creation'),
    canBeAssigned: i18next.t('errors.error403.canBeAssigned'),
    reachOutMainAdvisor: i18next.t('errors.error403.reachOutMainAdvisor'),
  };

  return (
    <Layout isSelection>
      <div className={style.container_fluid}>
        <div className={style.container}>
          <div className={style.container_text}>
            <DxText type="ps" color="white">
              {labels.status}
            </DxText>
            <DxText type="h2" color="white">
              {labels.accessDenied}
            </DxText>
          </div>
        </div>
        <DxFdSeparator fromcolor="gold" tocolor="white" />
      </div>
      <DxGrid mq5="12/*" style={{ textAlign: 'left' }}>
        <DxSpacer size="120v" />
        <DxSpacer size="8v" />
        <DxText color="gray-83" type="ps">
          {labels.insufficientPermission}
        </DxText>
        <DxSpacer size="4v" />
        <DxText color="gray-83" type="ps">
          {labels.grantPermission}
        </DxText>
        <DxTextList>
          <DxText color="gray-83"> {labels.access}</DxText>
          <DxText color="gray-83">{labels.search}</DxText>
          <DxText color="gray-83">{labels.creation}</DxText>
        </DxTextList>
        <DxText color="gray-83" type="ps">
          {labels.canBeAssigned}
        </DxText>
        <DxText color="gray-83" type="ps">
          {labels.reachOutMainAdvisor}
        </DxText>
      </DxGrid>
    </Layout>
  );
};

export default Error403;
