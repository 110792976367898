import styled, { css } from 'styled-components';
import { DxText, DxContainer } from '@dvag/design-system-react';

export const Title = styled(DxText)`
  ${({ theme: { fontSize } }) => css`
    font-size: ${fontSize.x15};
  `};
`;

export const Description = styled(DxText)`
  ${({ theme: { breakpoints, padding } }) => css`
    padding: ${padding.x0} ${padding.x8};
    @media (max-width: ${breakpoints.sm}) {
      padding: ${padding.x0};
    }
  `};
`;

export const InfoDescription = styled(DxText)`
  ${({ theme: { breakpoints, padding, fontSize } }) => css`
    font-size: ${fontSize.x4};
    line-height: ${fontSize.x7};
    padding: ${padding.x0} ${padding.x2};
    @media (max-width: ${breakpoints.sm}) {
      padding: ${padding.x0};
    }
  `};
`;

export const DvagButton = styled(DxContainer)`
  ${({ theme: { padding } }) => css`
    display: flex;
    justify-content: center;
    padding: ${padding.x3} ${padding.x0};
    width: ${padding.x12};
    align-self: center;
    cursor: pointer;
  `};
`;
