import { FC, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { logout } from '@dvag/dfs-orchestrator-client/messengers';

import ROUTES from './config/routes';
import Error403 from './screens/error';
import Login from './screens/login';

const { login } = ROUTES;

const App = () => {
  const location = useLocation();

  const fallbackComponent = ({ error }: { error: Error }) => {
    if (error.message === '403') return <Error403 />;
    return null;
  };

  const errorHandler = (error: Error) => {
    if (error.message === '401') logout();
  };

  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        FallbackComponent={fallbackComponent}
        resetKeys={[location]}
        onError={errorHandler}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={login} element={<Login />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

const Router: FC = () => (
  <main aria-label="Main rendering area">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </main>
);
export default Router;
