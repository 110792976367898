import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Router from './Router';
import theme from './config/theme';
import getAppInsights from './services/getAppInsights';

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={theme}>
    <AppInsightsContext.Provider value={getAppInsights().reactPlugin}>
      <Router />
    </AppInsightsContext.Provider>
  </ThemeProvider>
);

export default App;
