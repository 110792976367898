import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';

export const locales = {
  german: 'de',
  english: 'en',
};

export const resources = {
  en,
  de,
};

export const defaultNS = 'login';

export const defaultLocale = locales.german;

export const getLocale = (): string => i18n.language;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    detection: {
      order: ['navigator'],
    },
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    debug: false,
  });

export default i18n;
