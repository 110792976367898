import { DxMediaQueryValue, DxSpacer, DxText } from '@dvag/design-system-react';
import { login } from '@dvag/dfs-orchestrator-client/messengers';
import i18next from 'i18next';
import React, { useState } from 'react';
import CookieConsentModal from '../../components/CookieConsentModal';
import { isCookiesAccepted } from '../../components/CookieConsentModal/helpers';
import Layout from '../../components/Layout';
import { Description, DvagButton, InfoDescription, Title } from './styled';

const Login: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(isCookiesAccepted);

  const labels = {
    registration: i18next.t('loginPage.registration'),
    title: i18next.t('loginPage.title'),
    description: i18next.t('loginPage.description'),
    button: i18next.t('loginPage.button'),
    infoTitle: i18next.t('loginPage.infoTitle'),
    infoDescription: i18next.t('loginPage.infoDescription'),
  };

  if (!cookiesAccepted) {
    return (
      <Layout>
        <CookieConsentModal onConfirm={() => setCookiesAccepted(true)} />
      </Layout>
    );
  }

  return (
    <Layout>
      <DxText color="gold">{labels.registration}</DxText>
      <DxSpacer size="8v" />
      <Title type="h2" color="paragraph">
        {labels.title}
      </Title>
      <DxSpacer size="16v" />
      <Description type="ps">{labels.description}</Description>
      <DxSpacer>
        <DxMediaQueryValue
          mq1="16v"
          mq2="16v"
          mq3="48v"
          mq4="48v"
          mq5="48v"
          property="size"
        />
      </DxSpacer>
      <DvagButton id="dvagButton" onClick={login} color="default">
        <DxText type="its" color="white">
          {labels.button}
        </DxText>
      </DvagButton>
      <DxSpacer size="48v" />
      <DxText type="ps">{labels.infoTitle}</DxText>
      <DxSpacer>
        <DxMediaQueryValue
          mq1="16v"
          mq2="16v"
          mq3="32v"
          mq4="32v"
          mq5="32v"
          property="size"
        />
      </DxSpacer>
      <InfoDescription>{labels.infoDescription}</InfoDescription>
      <DxSpacer>
        <DxMediaQueryValue
          mq1="16v"
          mq2="16v"
          mq3="8v"
          mq4="8v"
          mq5="8v"
          property="size"
        />
      </DxSpacer>
      <DxSpacer size="4v" />
    </Layout>
  );
};

export default Login;
