import React from 'react';
import { Trans } from 'react-i18next';
import { DxLink, DxText, DxSpacer, DxFooter } from '@dvag/design-system-react';
import style from './Footer.module.css';

const FooterSelectionPage: React.FC = () => (
  <DxFooter
    fromcolor="background"
    className={style.ex_responsive_footer_content}
  >
    <div className={style.info_container}>
      <DxLink
        href="https://www.dvag.de/dvag/datenschutz.html"
        id="privacy-policy-link"
        target="_blank"
      >
        <DxText type="it" color="default">
          <Trans i18nKey="footer.link1" />
        </DxText>
      </DxLink>
      <DxSpacer size="24h" />
      <DxLink
        href="https://www.dvag.de/dvag/impressum.html"
        id="imprint-link"
        target="_blank"
      >
        <DxText type="it" color="default">
          <Trans i18nKey="footer.link2" />
        </DxText>
      </DxLink>
    </div>
  </DxFooter>
);

export default FooterSelectionPage;
