import React from 'react';
import { DxText, DxButton, DxLink, DxSpacer } from '@dvag/design-system-react';
import i18next from 'i18next';
import { acceptCookies } from './helpers';
import { CookieConsentContainer, LinkText } from './styled';

interface Props {
  onConfirm(): void;
}

const CookieConsentModal: React.FC<Props> = ({ onConfirm }: Props) => {
  const labels = {
    title: i18next.t('cookieConsent.title'),
    p1: i18next.t('cookieConsent.p1'),
    p2: i18next.t('cookieConsent.p2'),
    link: i18next.t('cookieConsent.link'),
    button: i18next.t('cookieConsent.button'),
  };

  const linkUrl = 'https://www.dvag.de/dvag/datenschutz.html';

  const onConfirmClick = () => {
    acceptCookies();
    onConfirm();
  };

  return (
    <CookieConsentContainer>
      <DxText color="paragraph">{labels.title}</DxText>
      <DxSpacer size="24v" />
      <DxText type="it" color="gray-83">
        {labels.p1}
      </DxText>
      <DxSpacer size="16v" />
      <DxText type="it" color="gray-83">
        {labels.p2}
        <DxLink href={linkUrl} id="pp-cookie-consent" target="_blank">
          <LinkText type="it" color="default">
            {labels.link}
          </LinkText>
        </DxLink>
      </DxText>
      <DxSpacer size="16v" />
      <DxButton
        id="cookie-consent-accept"
        type="primary-m"
        label={labels.button}
        onClick={onConfirmClick}
        stretch
      />
    </CookieConsentContainer>
  );
};

export default CookieConsentModal;
